/* eslint-disable react/display-name */
import React from 'react'
import {BlockMapForValue} from '../atoms/blockList'
import {BlockType, BlockValue} from './types'
import {TitleBlock} from './titleBlock'
import {ImageBlock} from './imageBlock'
import {EmbedBlock} from './embedBlock'
import {ImageGalleryBlock} from './imageGalleryBlock'
import {RichTextBlockExampleBlock} from './richTextBlockExample'
import {LinkListBlock} from './linkListBlock'
import {FaqListBlock} from './faqListBlock'
import {CodeSnippetBlock} from './codeSnippetBlock'
import {AccordionBlock} from './accordionBlock'
import {GenericBlock} from './genericBlock'
import {SliderBlockI18n} from './slider/sliderBlock'
import {TableBlock} from './tableBlock'

import Header from '@rsuite/icons/legacy/Header'
import Code from '@rsuite/icons/legacy/Code'
import FileText from '@rsuite/icons/legacy/FileText'
import Image from '@rsuite/icons/legacy/Image'
import Clone from '@rsuite/icons/legacy/Clone'
import ThList from '@rsuite/icons/legacy/ThList'
import Question from '@rsuite/icons/legacy/Question'
import Frame from '@rsuite/icons/legacy/Frame'
import Bars from '@rsuite/icons/legacy/Bars'
import Attachment from '@rsuite/icons/legacy/Attachment'
import Filter from '@rsuite/icons/legacy/Filter'
import NewspaperO from '@rsuite/icons/legacy/NewspaperO'
import Rocket from '@rsuite/icons/legacy/Rocket'
import Th from '@rsuite/icons/legacy/Th'
import CodeFork from '@rsuite/icons/legacy/CodeFork'
import Table from '@rsuite/icons/legacy/Table'
import ArrowsH from '@rsuite/icons/legacy/ArrowsH'
import EllipsisH from '@rsuite/icons/legacy/EllipsisH'
import DataDecrease from '@rsuite/icons/legacy/DataDecrease'
import Space from '@rsuite/icons/legacy/ArrowsV'
import QuoteLeft from '@rsuite/icons/legacy/QuoteLeft'

export const BlockMap: BlockMapForValue<BlockValue> = {
  [BlockType.Title]: {
    field: (props) => <TitleBlock {...props} />,
    label: 'Title',
    icon: <Header />,
  },

  [BlockType.CodeSnippet]: {
    field: (props) => <CodeSnippetBlock {...props} />,
    label: 'iFrame',
    icon: <Code />,
  },

  [BlockType.RichText]: {
    field: (props) => <RichTextBlockExampleBlock {...props} />,
    label: 'Rich Text',
    icon: <FileText />,
  },

  [BlockType.Image]: {
    field: (props) => <ImageBlock {...props} />,
    label: 'Image',
    icon: <Image />,
  },

  [BlockType.ImageGallery]: {
    field: (props) => <ImageGalleryBlock {...props} />,
    label: 'Gallery',
    icon: <Clone />,
  },

  [BlockType.LinkList]: {
    field: (props) => <LinkListBlock {...props} />,
    label: 'Link List',
    icon: <ThList />,
  },

  [BlockType.FaqList]: {
    field: (props) => <FaqListBlock {...props} />,
    label: 'Faq List',
    icon: <Question />,
  },

  [BlockType.Embed]: {
    field: (props) => <EmbedBlock {...props} />,
    label: 'Embed',
    icon: <Frame />,
  },

  [BlockType.Accordion]: {
    field: (props) => <AccordionBlock {...props} />,
    label: 'Accordion',
    icon: <Bars />,
  },

  [BlockType.TeaserSection]: {
    field: (props) => <GenericBlock {...props} />,
    label: 'Teaser Section',
    icon: <Attachment />,
  },
  [BlockType.FilterViews]: {
    field: (props) => <GenericBlock {...props} />,
    label: 'Filter Views',
    icon: <Filter />,
  },
  [BlockType.News]: {
    field: (props) => <GenericBlock {...props} />,
    label: 'News',
    icon: <NewspaperO />,
  },
  [BlockType.Hero]: {
    field: (props) => <GenericBlock {...props} />,
    label: 'Hero',
    icon: <Rocket />,
  },
  [BlockType.NewsGrid]: {
    field: (props) => <GenericBlock {...props} />,
    label: 'NewsGrid',
    icon: <Th />,
  },
  [BlockType.Slider]: {
    field: (props) => <SliderBlockI18n {...props} />,
    label: 'Slider',
    icon: <Clone />,
  },
  [BlockType.TeaserSlider]: {
    field: (props) => <GenericBlock {...props} />,
    label: 'Teaser Slider',
    icon: <Clone />,
  },
  [BlockType.Custom]: {
    field: (props) => <GenericBlock {...props} />,
    label: 'Custom',
    icon: <CodeFork />,
  },
  [BlockType.Anchor]: {
    field: (props) => <GenericBlock {...props} />,
    label: 'Anchor',
    icon: <Clone />,
  },
  [BlockType.TeaserCarousel]: {
    field: (props) => <GenericBlock {...props} />,
    label: 'Teaser Carousel',
    icon: <ArrowsH />,
  },
  [BlockType.OverviewResults]: {
    field: (props) => <GenericBlock {...props} />,
    label: 'Overview Results',
    icon: <EllipsisH />,
  },
  [BlockType.Table]: {
    field: (props) => <TableBlock {...props} />,
    label: 'Table',
    icon: <Table />,
  },
  [BlockType.CtaButton]: {
    field: (props) => <GenericBlock {...props} />,
    label: 'CTA Button',
    icon: <DataDecrease />,
  },
  [BlockType.Spacer]: {
    field: (props) => <GenericBlock {...props} />,
    label: 'Spacer',
    icon: <Space />,
  },
  [BlockType.CustomQuote]: {
    field: (props) => <GenericBlock {...props} />,
    label: 'Quote',
    icon: <QuoteLeft />,
  },
}
