import {BlockListValue} from '../atoms/blockList'
import {ListWrapper, Reference, RichTextBlockValue} from '@dudagroup/editor'
import {I18n} from '../interfaces/interfaces'

export enum BlockType {
  RichText = 'richText',
  Title = 'title',
  Custom = 'custom',
  CodeSnippet = 'codeSnippet',
  Image = 'image',
  ImageGallery = 'imageGallery',
  LinkList = 'linkList',
  FaqList = 'faqList',
  Accordion = 'accordion',
  Table = 'table',
  Embed = 'embed',
  LinkPageBreak = 'linkPageBreak',
  TeaserGrid1 = 'teaserGrid1',
  TeaserGrid6 = 'teaserGrid6',
  TeaserSection = 'teaserSection',
  FilterViews = 'filterViews',
  News = 'news',
  Hero = 'hero',
  NewsGrid = 'newsGrid',
  Slider = 'slider',
  TeaserSlider = 'teaserSlider',
  Anchor = 'anchor',
  TeaserCarousel = 'teaserCarousel',
  OverviewResults = 'overviewResults',
  CtaButton = 'ctaButton',
  Spacer = 'spacer',
  CustomQuote = 'customQuote',
}

enum BgColor {
  gray = 'gray',
}

export interface RichTextBlockExampleValue {
  text: I18n<RichTextBlockValue>
  backgroundColor: BgColor
}

export interface ImageBlockValue {
  image: Reference | null
  caption: I18n<string>
  hasBorder: boolean
  borderColor: BgColor
}

export interface GalleryImageEdge {
  image: Reference | null
  caption: I18n<string>
}

export interface ImageGalleryBlockValue {
  images: GalleryImageEdge[]
}

export interface SliderDefaultEdge {
  image: Reference | null
  text: I18n<string>
}

export type SliderBlockValueUnion = {default: SliderDefaultEdge} | {youTubeVideo: YouTubeVideoEmbed}

export interface SliderBlockValue {
  slider: ListWrapper<SliderBlockValueUnion>[]
}

export interface TitleBlockValue {
  title: I18n<string>
  lead: I18n<string>
}

export interface CodeSnippetValue {
  snippet: I18n<string>
  size?: {
    aspectRatio?: {
      desktop: string
      mobile: string
    }
  }
}

export interface InternalLink {
  link?: Reference
  queryParams?: string
  fragment?: string
}

export enum ExternalLinkTarget {
  _self = '_self',
  _blank = '_blank',
}

export interface ExternalLink {
  href: I18n<string>
  target?: ExternalLinkTarget
  nofollow: boolean
}

export type LinkObject = {external: ExternalLink} | {internal: InternalLink}

export interface LinkListBlockValueItem {
  linkText: I18n<string>
  link: LinkObject
}

export enum ListTypeBlockValue {
  list = 'list',
  unorderedList = 'unorderedList',
  orderedList = 'orderedList',
}

export interface LinkListBlockValue {
  title: I18n<string>
  listType: ListTypeBlockValue
  linkList: ListWrapper<LinkListBlockValueItem>[]
}

export interface FaqListBlockValue {
  faqList: ListWrapper<Reference>[]
}

export interface AccordionBlockValueItem {
  id: string
  title: I18n<string>
  content: {
    [key: string]: BlockValue
  }[]
}

export interface AccordionBlockValue {
  backgroundColor: BgColor
  accordion: ListWrapper<AccordionBlockValueItem>[]
}

export type GenricBlockValue = any

export interface FacebookPostEmbed {
  userID: string
  postID: string
}

export interface FacebookVideoEmbed {
  userID: string
  videoID: string
}

export interface InstagramPostEmbed {
  postID: string
}

export interface TwitterTweetEmbed {
  userID: string
  tweetID: string
}

export interface VimeoVideoEmbed {
  videoID: I18n<string>
}

export interface YouTubeVideoEmbed {
  videoID: I18n<string>
}

export interface SoundCloudTrackEmbed {
  trackID: string
}

export interface OtherEmbed {
  url: I18n<string>
  title: I18n<string>
  width: I18n<number>
  height: I18n<number>
  styleCustom: I18n<string>
}

export interface UndefinedEmbed {
  type: EmbedType.Undefined
  url?: string
  title?: string
  width?: number
  height?: number
  styleCustom?: string
}

export interface TableBlockValueRow {
  id: string
  content: any
}

export interface TableBlockValue {
  columnCount: number
  rows: ListWrapper<TableBlockValueRow>[]
  header: {
    active: boolean
    content: any
  }
  cellWidth: {
    content: any
  }
  disableMargin: boolean
}

export enum EmbedType {
  FacebookPost = 'facebookPost',
  FacebookVideo = 'facebookVideo',
  InstagramPost = 'instagramPost',
  TwitterTweet = 'twitterTweet',
  VimeoVideo = 'vimeoVideo',
  YouTubeVideo = 'youTubeVideo',
  SoundCloudTrack = 'soundCloudTrack',
  Other = 'other',
  Undefined = '',
}

export type EmbedBlockValue = {
  embedCode?: I18n<string>
  type: EmbedBlockTypeUnion
}

export type EmbedBlockTypeUnion = {
  [EmbedType.Other]?: OtherEmbed
  [EmbedType.VimeoVideo]?: VimeoVideoEmbed
  [EmbedType.YouTubeVideo]?: YouTubeVideoEmbed
  [EmbedType.TwitterTweet]?: TwitterTweetEmbed
  [EmbedType.FacebookPost]?: FacebookPostEmbed
  [EmbedType.FacebookVideo]?: FacebookVideoEmbed
  [EmbedType.InstagramPost]?: InstagramPostEmbed
  [EmbedType.SoundCloudTrack]?: SoundCloudTrackEmbed
}

export enum MetaDataType {
  General = 'general',
  SocialMedia = 'socialMedia',
}

export interface BaseTeaser {
  // style: TeaserStyle
  // image?: ImageRefFragment
  preTitle?: string
  title?: string
  lead?: string
}

export interface TeaserGridBlockValue {
  teasers: Array<[string, BaseTeaser | null]>
  numColumns: number
}

export type RichTextBlockListValue = BlockListValue<BlockType.RichText, RichTextBlockExampleValue>
export type ImageBlockListValue = BlockListValue<BlockType.Image, ImageBlockValue>
export type ImageGalleryBlockListValue = BlockListValue<
  BlockType.ImageGallery,
  ImageGalleryBlockValue
>
export type LinkListBlockListValue = BlockListValue<BlockType.LinkList, LinkListBlockValue>
export type FaqListBlockListValue = BlockListValue<BlockType.FaqList, FaqListBlockValue>
export type AccordionBlockListValue = BlockListValue<BlockType.Accordion, AccordionBlockValue>
export type TitleBlockListValue = BlockListValue<BlockType.Title, TitleBlockValue>
export type CodeSnippetBlockListValue = BlockListValue<BlockType.CodeSnippet, CodeSnippetValue>
export type EmbedBlockListValue = BlockListValue<BlockType.Embed, EmbedBlockValue>
export type TeaserGridBlock1ListValue = BlockListValue<BlockType.TeaserGrid1, TeaserGridBlockValue>
export type TeaserGridBlock6ListValue = BlockListValue<BlockType.TeaserGrid6, TeaserGridBlockValue>

export type OverviewBlockValue = BlockListValue<BlockType.TeaserSection, GenricBlockValue>
export type FilterViewsBlockValue = BlockListValue<BlockType.FilterViews, GenricBlockValue>
export type NewsBlockListValue = BlockListValue<BlockType.News, GenricBlockValue>
export type HeroBlockListValue = BlockListValue<BlockType.Hero, GenricBlockValue>
export type NewsGridBlockListValue = BlockListValue<BlockType.NewsGrid, GenricBlockValue>
export type SliderBlockListValue = BlockListValue<BlockType.Slider, SliderBlockValue>
export type TeaserSliderBlockListValue = BlockListValue<BlockType.TeaserSlider, GenricBlockValue>
export type CustomBlockListValue = BlockListValue<BlockType.Custom, GenricBlockValue>
export type AnchorBlockValue = BlockListValue<BlockType.Anchor, GenricBlockValue>
export type SpacerBlockValue = BlockListValue<BlockType.Spacer, GenricBlockValue>
export type TableBlockListValue = BlockListValue<BlockType.Table, TableBlockValue>
export type TeaserCarouselBlockValue = BlockListValue<BlockType.TeaserCarousel, GenricBlockValue>
export type OverviewResultsBlockValue = BlockListValue<BlockType.OverviewResults, GenricBlockValue>
export type CtaButtonBlockValue = BlockListValue<BlockType.CtaButton, GenricBlockValue>
export type CustomQuoteBlockValue = BlockListValue<BlockType.CustomQuote, GenricBlockValue>

export type BlockValue =
  | TitleBlockListValue
  | RichTextBlockListValue
  | ImageBlockListValue
  | ImageGalleryBlockListValue
  | LinkListBlockListValue
  | FaqListBlockListValue
  | EmbedBlockListValue
  | CodeSnippetBlockListValue
  | AccordionBlockListValue
  | OverviewBlockValue
  | FilterViewsBlockValue
  | NewsBlockListValue
  | HeroBlockListValue
  | NewsGridBlockListValue
  | SliderBlockListValue
  | TeaserSliderBlockListValue
  | CustomBlockListValue
  | AnchorBlockValue
  | SpacerBlockValue
  | TableBlockListValue
  | TeaserCarouselBlockValue
  | OverviewResultsBlockValue
  | CtaButtonBlockValue
  | CustomQuoteBlockValue
